@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: minion-pro;
  font-weight: 700;
  src: url('/fonts/MinionPro-Bold.woff') format("woff");
  font-display: swap;
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/source-sans-pro-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
  url('/fonts/source-sans-pro-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/source-sans-pro-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/source-sans-pro-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/source-sans-pro-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
  url('/fonts/source-sans-pro-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/source-sans-pro-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/source-sans-pro-v13-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/fonts/source-sans-pro-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/source-sans-pro-v13-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Regular'), url('/fonts/MinionPro-Regular.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Minion Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Italic'), url('/fonts/MinionPro-It.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Minion Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Medium'), url('/fonts/MinionPro-Medium.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Minion Pro Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Medium Italic'), url('/fonts/MinionPro-MediumIt.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Semibold'), url('/fonts/MinionPro-Semibold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Semibold Italic'), url('/fonts/MinionPro-SemiboldIt.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Bold Condensed'), url('/fonts/MinionPro-BoldCn.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Bold'), url('/fonts/MinionPro-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Bold Condensed Italic'), url('/fonts/MinionPro-BoldCnIt.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Minion Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Minion Pro Bold Italic'), url('/fonts/MinionPro-BoldIt.woff') format('woff');
  font-display: swap;
}

/*
Mobile
 */

.claim {
  @apply text-l text-center leading-huge;
}

/*
Headline
 */
h1, h2 {
  @apply uppercase text-s text-center leading-small text-tundora;
  padding: 0 0 2.5rem;
}

h3 {
  @apply font-bold
}

body {
  @apply text-xxxs leading-tiny text-tundora;
}

.small-font, small {
  @apply text-xxxs leading-tiny;
}

button {
  @apply text-xxxs leading-small;
}

/* Tablet */
@screen md {
  .claim {
    @apply text-xxl leading-gigantic;
  }

  h1, h2 {
    @apply text-m;
    @apply leading-huge;
  }

  body {
    @apply text-s;
    @apply leading-large;
  }

  .small-font, small {
    @apply text-xxxs;
    @apply leading-tiny;
  }

  button {
    @apply text-xxxs;
    @apply leading-small;
  }

  .columns-2 {
    column-count: 2;
  }

}

/* Desktop */
@screen lg {
  .claim {
    @apply text-xxxl;
    @apply leading-gigantic;
  }

  h1, h2 {
    @apply text-xl;
    @apply leading-huge;
  }

  body {
    @apply text-s;
    @apply leading-large;
  }

  .small-font, small {
    @apply text-xs;
    @apply leading-small;
  }

  button {
    @apply text-xs;
    @apply leading-small;
  }

  .columns-2 {
    column-count: 2;
  }
}


/**
* Custom styles
 */
html, body {
  font-family: 'Source Sans Pro', sans-serif;
}

a {
  @apply font-bold;
}

.kidler-button {
  @apply border;
  @apply border-tundora;
  @apply py-2 ;
  @apply px-8 ;
  @apply uppercase ;
  @apply w-fit-content ;
  @apply font-bold;
}

nav {
  a {
    font-weight: normal;
  }
}

button {
  @apply cursor-pointer;
}

p {
  @apply mb-4;
}

.container ul {
  @apply ml-6;
  list-style-type: disc;
}

.top-10 {
  top: 10%;
}

.bottom-20 {
  bottom: 20%;
}

.w-fit-content {
  width: fit-content;
}

.bg-theme {
  background: var(--theme-background);
}

.bg-theme-light {
  background: var(--theme-background-light);
}

.image-overlay {
    @apply bg-white uppercase absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.opacity-90 {
  opacity: 0.9;
}

.opacity-20 {
  opacity: 0.2;
}

.minion-bold {
  font-family: minion-pro, serif;
  font-style: normal;
  font-weight: 700;
}

.hamburger-icon {
  width: 30px;
  height: 26px;
  border: none;
  position: relative;
  background: linear-gradient(to bottom, #4A4A4A, #4A4A4A);
  background-size: 100% 20%;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-size 0.2s 0.25s ease;
  cursor: pointer;
}

.hamburger-icon:before,
.hamburger-icon:after {
  height: 20%;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: #4A4A4A;
  content: '';
  transition: transform 0.3s ease;
}

.hamburger-icon:before {
  top: 0;
  transform-origin: 9% 150%;
}

.hamburger-icon:after {
  bottom: 0;
  transform-origin: 9% -50%;
}

.hamburger-icon.is-active {
  background-size: 0 20%;
  transition-delay: 0s;
}

.hamburger-icon.is-active:before {
  transform: rotate(45deg);
  transition-delay: 0.15s;
}

.hamburger-icon.is-active:after {
  transform: rotate(-45deg);
  transition-delay: 0.15s;
}


// TODO: Other viewports
@media (min-width: 1200px) {
  .container {
    max-width: 1000px;
  }
}

.header-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

$header-height: 4.5rem;

.h-header {
  height: $header-height;
}

.mt-header {
  margin-top: $header-height;
}

.min-h-header {
  min-height: $header-height;
}

.h-screen-without-header {
  max-height: calc(85vh - #{$header-height});
}

.tile {
  p {
    margin: 0;
  }
}

/**
* Plyr
 */
:root {
  --plyr-audio-controls-background: transparent;
}

.move-24px-left {
  margin-left: -24px;
}

.media-item-height {
  height: 333px;
}

@import '~plyr/src/sass/plyr.scss';


